<template>
    <div class="user-profile">
        <div class="box">
            <h2>ข้อมูลส่วนตัว</h2>

            <div class="loading" v-if="!isContentReady">
                <loader-content :isDisplay="true"></loader-content>
            </div>

            <div class="form profile" v-if="isContentReady">
                <div class="input-field">
                    <label>รหัสลูกค้า</label>
                    <input type="text"
                        class="square grey"
                        :value="profile.customerId"
                        readonly />
                </div>
                <div class="input-field">
                    <label>ชื่อผู้ใช้</label>
                    <input type="text"
                        class="square grey"
                        :value="profile.username"
                        readonly />
                </div>
                <div class="input-field">
                    <label>ชื่อ-นามสกุล</label>
                    <div class="group-field">
                        <template v-if="profile.fullName || isOpenChangeName">
                            <input type="text"
                                v-model="name"
                                class="square"
                                :class="{ 'active': isOpenChangeName }"
                                :readonly="!isOpenChangeName" />
                            <button @click="openChangeName()" v-if="!isOpenChangeName">เปลี่ยน</button>
                            <button @click="saveName()" v-if="isOpenChangeName">บันทึก</button>
                        </template>
                        <button class="btn-add" v-else @click="openChangeName()">เพิ่มข้อมูล</button>
                    </div>
                </div>
                <div class="input-field">
                    <label>หมายเลขโทรศัพท์</label>
                    <div class="group-field">
                        <input type="text"
                            class="square"
                            :value="profile.tel"
                            readonly />
                        <button @click="openTelModal()">เปลี่ยน</button>
                    </div>
                </div>
                <div class="input-field">
                    <label>อีเมล</label>
                    <div class="group-field">
                        <template v-if="profile.email || isOpenChangeEmail">
                            <input type="text"
                                v-model="email"
                                class="square"
                                :class="{ 'active': isOpenChangeEmail }"
                                :readonly="!isOpenChangeEmail" />
                            <button @click="openChangeEmail()" v-if="!isOpenChangeEmail">เปลี่ยน</button>
                            <button @click="saveEmail()" v-if="isOpenChangeEmail">บันทึก</button>
                        </template>
                        <button v-else @click="openChangeEmail()">เพิ่มข้อมูล</button>
                    </div>
                </div>
                <div class="input-field">
                    <label>วันเดือนปีเกิด</label>
                    <div class="group-field">
                        <template v-if="profile.birthDate || isOpenChangeBirthDate">
                            <div class="input-date-icon">
                                <datepicker
                                    v-model="birthDate"
                                    :input-class="[ 'input-date', 'square', (isOpenChangeBirthDate ? 'active' : '') ]"
                                    :language="calendarTH"
                                    :full-month-name="true"
                                    calendar-class="datepicker-box"
                                    :disabled="!isOpenChangeBirthDate"
                                    format="d/M/yyyy">
                                </datepicker>
                                <b-icon icon="calendar"></b-icon>
                            </div>
                            <button @click="openChangeBirthDate()" v-if="!isOpenChangeBirthDate">เปลี่ยน</button>
                            <button @click="saveBirthDate()" v-if="isOpenChangeBirthDate">บันทึก</button>
                        </template>
                        <button class="btn-add" v-else @click="openChangeBirthDate()">เพิ่มข้อมูล</button>
                    </div>
                </div>
                <div class="input-field">
                    <label>ที่อยู่กรณีจัดส่งสินค้า</label>
                    <div class="group-field">
                        <template v-if="profile.fullAddress">
                            <input type="text"
                                class="square long"
                                :value="profile.fullAddress"
                                readonly />
                            <button @click="openAddressModal()">เปลี่ยน</button>
                        </template>
                        <button class="btn-add" v-else @click="openAddressModal()">เพิ่มข้อมูล</button>
                    </div>
                </div>
                <div class="input-field">
                    <label>เพศ</label>
                    <div class="group-field">
                        <template v-if="profile.gender || isOpenChangeGender">
                            <dropdown
                                v-model="gender"
                                placeholder="-- โปรดเลือก --"
                                :diabled="!isOpenChangeGender"
                                :options="optionGender"
                                :classes="{ input: ['square', (isOpenChangeGender ? 'active' : '')] }">
                            </dropdown>
                            <button @click="openChangeGender()" v-if="!isOpenChangeGender">เปลี่ยน</button>
                            <button @click="saveGender()" v-if="isOpenChangeGender">บันทึก</button>
                        </template>
                        <button class="btn-add" v-else @click="openChangeGender()">เพิ่มข้อมูล</button>
                    </div>
                </div>
            </div>
        </div>

        <modal-address
            @close="closeAddressModal($event)"
            :profile="profile"
            :isDisplay="isOpenAddressModal">
        </modal-address>
        <modal-tel
            @close="closeTelModal($event)"
            :isDisplay="isOpenTelModal">
        </modal-tel>
    </div>
</template>

<script>
import { th } from 'vuejs-datepicker/dist/locale';
import Helper from '@/services/helper';
import UserService from '@/services/userService';
import OptionGender from '@/services/staticOption/gender';
import ModalAddress from '@/components/user/ModalAddress.vue';
import ModalTel from '@/components/user/ModalTel.vue';
import RouterPath from '@/router/path';

export default {
    components: { ModalAddress, ModalTel },
    data() {
        return {
            calendarTH: th,
            isContentReady: false,
            profile: null,
            name: '',
            email: '',
            birthDate: '',
            gender: '',
            isOpenChangeName: false,
            isOpenChangeEmail: false,
            isOpenChangeBirthDate: false,
            isOpenChangeGender: false,
            isOpenAddressModal: false,
            isOpenTelModal: false,
            optionGender: OptionGender.option,
        };
    },
    mounted() {
        this.loadProfile();
    },
    methods: {
        async loadProfile() {
            this.isContentReady = false;

            const result = await UserService.getProfile();

            if (result.status == 401) {
                UserService.clearUserToken();
				this.$router.push(RouterPath.LOGIN);
			}

            this.profile = result.data;
            this.birthDate = this.profile.birthDate;
            this.name = this.profile.fullName;
            this.email = this.profile.email;
            this.gender = this.profile.gender;
            this.isContentReady = true;
        },
        openChangeName() {
            this.isOpenChangeName = true;
        },
        openChangeEmail() {
            this.isOpenChangeEmail = true;
        },
        async saveName() {
            if (this.name.trim()) {
                let loader = this.$modalLoader.render();
                const result = await UserService.saveName(this.name);

                if (result.data?.success) {
                    this.isOpenChangeName = false;
                    this.loadProfile();
				} else {
					this.$swal(Helper.warningAlert('ไม่สามารถเปลี่ยนอีเมลได้', result.data?.message));
				}

                loader.hide();
            }
        },
        async saveEmail() {
            if (this.email.trim()) {
                let loader = this.$modalLoader.render();
                const result = await UserService.saveEmail(this.email);

                if (result.data?.success) {
                    this.isOpenChangeEmail = false;
                    this.loadProfile();
				} else {
					this.$swal(Helper.warningAlert('ไม่สามารถเปลี่ยนอีเมลได้', result.data?.message));
				}

                loader.hide();
            }
        },
        openChangeBirthDate() {
            this.isOpenChangeBirthDate = true;
        },
        async saveBirthDate() {
            if (this.birthDate) {
                let loader = this.$modalLoader.render();
                const result = await UserService.saveBirthDate(this.birthDate);

                if (result.data?.success) {
                    this.isOpenChangeBirthDate = false;
                    this.loadProfile();
				} else {
					this.$swal(Helper.warningAlert('ไม่สามารถเปลี่ยนอีเมลได้', result.data?.message));
				}

                loader.hide();
            }
        },
        openChangeGender() {
            this.isOpenChangeGender = true;
        },
        async saveGender() {
            if (this.gender) {
                let loader = this.$modalLoader.render();
                await UserService.saveGender(this.gender);
                loader.hide();

                this.isOpenChangeGender = false;
                this.loadProfile();
            }
        },
        openAddressModal() {
            this.isOpenAddressModal = true;
        },
        closeAddressModal(data) {
            this.isOpenAddressModal = false;

            if (data.isReload) {
                this.loadProfile();
            }
        },
        openTelModal() {
            this.isOpenTelModal = true;
        },
        closeTelModal(data) {
            this.isOpenTelModal = false;

            if (data.isReload) {
                this.loadProfile();
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/components/user/style/ProfileSetting.scss';

.loading {
    height: 400px;
}
</style>