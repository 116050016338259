<template>
    <transition name="modal" v-if="isActive">
        <perfect-scrollbar class="modal-mask">
            <div class="modal-form modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4>
                            <b-icon icon="pencil-square"></b-icon>
                            <span>เปลี่ยนเบอร์ติดต่อ</span>
                        </h4>

                        <button class="btn-close" @click="hide(false)"><b-icon icon="x"></b-icon></button>
                    </div>
                    <div class="modal-body">
                        <div class="form">
                           <div class="input-field">
                                <label>หมายเลขโทรศัพท์ใหม่</label>
                                <div class="input-button">
                                    <input type="tel"
                                        @keypress="$AppUtility.inputOnlyNumber($event)"
                                        maxlength="12"
                                        v-model="form.tel"
                                        placeholder="เบอร์ใหม่"
                                        class="square lg" 
                                        :class="{ 'invalid': error.tel }"
                                        :readonly="countDownOTP > 0 || step > 2" />
                                    <button class="btn btn-main square" @click="requestOTP()" v-if="step === 1">ขอ OTP</button>
                                    <button class="btn btn-main square" @click="requestOTP()" v-if="step === 2 && countDownOTP === 0">ส่งอีกครั้ง</button>
                                </div>
                                <div class="otp-countdown" v-if="countDownOTP > 0">ลองใหม่อีกครั้งใน <span>{{ helper.displayCountDownOTP(countDownOTP) }}</span></div>
                            </div>

                            <div class="input-field" v-if="step === 2">
                                <label>SMS ยืนยันตัวตน</label>
                                <input type="tel"
                                    @keypress="$AppUtility.inputOnlyNumber($event)"
                                    maxlength="6"
                                    v-model="form.otp"
                                    placeholder="ตัวเลข 6 หลัก"
                                    class="square lg" 
                                    :class="{ 'invalid': error.otp }" />
                            </div>

                            <div class="mt-4 text-center" v-if="step === 2">
                                <button class="btn btn-main btn-submit" @click="confirmOTP()">ยืนยันและเปลี่ยนเบอร์</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </perfect-scrollbar>
    </transition>
</template>

<script>
import Helper from '@/services/helper';
import UserService from '@/services/userService';

export default {
    data() {
		return {
            helper: Helper,
            isActive: false,
            form: this.initForm(),
            error: this.initError(),
            step: 1,
            countDownOTP: 0,
            otpToken: ''
		};
	},
	props: {
		isDisplay: Boolean,
	},
    watch: {
        async isDisplay(value) {
            this.isActive = value;
            this.$root.$emit('rootSetContentScrollable', value);
        }
    },
    destroyed() {
        this.$root.$emit('rootSetContentScrollable', false);
    },
	methods: {
        hide(isReload) {
            this.$emit('close', { isReload: isReload });
        },
        initForm() {
            return {
                tel: '',
                otp: ''
            };
        },
        initError() {
            return {
                tel: false,
                otp: false
            };
        },
        countDownTimer() {
            if (this.countDownOTP > 0) {
                setTimeout(() => {
                    this.countDownOTP -= 1
                    this.countDownTimer()
                }, 1000);
            }
        },
        async requestOTP() {
            if (this.validateTel()) {
                let loader = this.$modalLoader.render();

                const recaptcha = await this.getRecaptchaToken();
                const result = await UserService.requestOTP(this.form.tel, recaptcha);

                loader.hide();

                if (result.data?.success) {
                    this.countDownOTP = result.data.remainTime;
                    this.countDownTimer();
                    this.step = 2;
                    this.otpToken = result.data.token;
                } else {
                    this.$swal(Helper.warningAlert('ขอ OTP ไม่สำเร็จ', result.data?.message));
                }
            }
        },
        async getRecaptchaToken() {
            await this.$recaptchaLoaded()

            const recaptcha = await this.$recaptcha('changeTel');

            return recaptcha;
        },
        validateTel() {
            this.error = this.initError();
            let isValid = true;
            let isBlank = false;

            // Check Blank
            if (!this.form.tel?.trim()) {
                this.error.tel = true;
                isValid = false;
                isBlank = true;
            }

            // Popup
            if (isBlank) {
                this.$swal(Helper.warningAlert('ขอ OTP ไม่สำเร็จ', 'โปรดกรอกเบอร์โทรศัพท์ของท่าน'));
            }

            return isValid;
        },
        async confirmOTP() {
            if (this.validateOTP()) {
                let loader = this.$modalLoader.render();
                const result = await UserService.submitOTP('change', this.form.tel, this.form.otp, this.otpToken);
                loader.hide();

                if (result.data?.success) {
                    this.hide(true);
                } else {
                    this.$swal(Helper.warningAlert('ยืนยัน OTP ไม่สำเร็จ', result.data?.message));
                }
            }
        },
        validateOTP() {
            this.error = this.initError();
            let isValid = true;
            let isBlank = false;

            // Check Blank
            if (!this.form.otp?.trim()) {
                this.error.otp = true;
                isValid = false;
                isBlank = true;
            }

            // Popup
            if (isBlank) {
                this.$swal(Helper.warningAlert('ลงทะเบียนไม่สำเร็จ', 'โปรดกรอกหมายเลข OTP'));
            }

            return isValid;
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.modal-form {
    max-width: 400px;
}
</style>