const option = [
    { id: 'male', name: 'ชาย'},
    { id: 'female', name: 'หญิง'}
];

const method = {
    getValueByKey(id) {
        return option.find(x => x.id === id).name;
    }
}

export default {
    option: option,
    service: method
};