<template>
    <transition name="modal" v-if="isActive">
        <perfect-scrollbar class="modal-mask">
            <div class="modal-form modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4>
                            <b-icon icon="pencil-square"></b-icon>
                            <span>ข้อมูลที่อยู่</span>
                        </h4>

                        <button class="btn-close" @click="hide(false)"><b-icon icon="x"></b-icon></button>
                    </div>
                    <div class="modal-body">
                        <div class="form" v-if="form">
                            <div class="row">
                                <div class="col-12">
                                    <div class="input-field">
                                        <label>ที่อยู่</label>
                                        <input type="text"
                                            v-model="form.address"
                                            maxlength="100"
                                            placeholder="บ้านเลขที่/หมู่บ้าน/ซอย/ถนน"
                                            class="square lg"
                                            :class="{ 'invalid': error.address }" />
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-12 col-md-6">
                                    <div class="input-field">
                                        <label>ตำบล/แขวง</label>
                                        <address-auto-complete
                                            ref="thaiAddressDataTumbon"
                                            placeholder="ตำบล/แขวง"
                                            :listData="mixinsThaiAddressList"
                                            v-model="form.subDistrict"
                                            :inputClass="['square', 'lg', (error.subDistrict ? 'invalid' : '')]"
                                            @hit="mixinsThaiAddressSetThaiAddressValue($event)"
                                            @input="mixinsThaiAddressSetAutoComplete($event, 'tumbon')"
                                            @blur="mixinsThaiAddressCheckValidInputAddress($event, 'tumbon')" />
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <div class="input-field">
                                        <label>อำเภอ/เขต</label>
                                        <address-auto-complete
                                            ref="thaiAddressDataAmphur"
                                            placeholder="อำเภอ/เขต"
                                            :listData="mixinsThaiAddressList"
                                            v-model="form.district"
                                            :inputClass="['square', 'lg', (error.district ? 'invalid' : '')]"
                                            @hit="mixinsThaiAddressSetThaiAddressValue($event)"
                                            @input="mixinsThaiAddressSetAutoComplete($event, 'amphur')"
                                            @blur="mixinsThaiAddressCheckValidInputAddress($event, 'amphur')" />
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <div class="input-field">
                                        <label>จังหวัด</label>
                                        <address-auto-complete
                                            ref="thaiAddressDataProvince"
                                            placeholder="จังหวัด"
                                            :listData="mixinsThaiAddressList"
                                            v-model="form.province"
                                            :inputClass="['square', 'lg', (error.province ? 'invalid' : '')]"
                                            @hit="mixinsThaiAddressSetThaiAddressValue($event)"
                                            @input="mixinsThaiAddressSetAutoComplete($event, 'province')"
                                            @blur="mixinsThaiAddressCheckValidInputAddress($event, 'province')" />
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <div class="input-field">
                                        <label>รหัสไปรษณีย์</label>
                                        <address-auto-complete
                                            ref="thaiAddressDataPostCode"
                                            placeholder="รหัสไปรษณีย์"
                                            :listData="mixinsThaiAddressList"
                                            v-model="form.postCode"
                                            :inputClass="['square', 'lg', (error.province ? 'invalid' : '')]"
                                            @hit="mixinsThaiAddressSetThaiAddressValue($event)"
                                            @input="mixinsThaiAddressSetAutoComplete($event, 'postcode')"
                                            @blur="mixinsThaiAddressCheckValidInputAddress($event, 'postcode')" />
                                    </div>
                                </div>
                            </div>

                            <div class="mt-4 text-center">
                                <button class="btn btn-main btn-submit" @click="saveAddress()">บันทึก</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </perfect-scrollbar>
    </transition>
</template>

<script>
import ThaiAddress from '@/mixins/thaiAddress';
import Helper from '@/services/helper';
import UserService from '@/services/userService';

export default {
    mixins: [ ThaiAddress ],
    data() {
		return {
            isActive: false,
            form: null,
            error: this.initError()
		};
	},
	props: {
		isDisplay: Boolean,
        profile: Object
	},
    watch: {
        async isDisplay(value) {
            this.isActive = value;
            this.$root.$emit('rootSetContentScrollable', value);

            if (value) {
                this.form = this.initForm();
            }
        }
    },
    destroyed() {
        this.$root.$emit('rootSetContentScrollable', false);
    },
	methods: {
        hide(isReload) {
            this.$emit('close', { isReload: isReload });
        },
        initForm() {
            return {
                address: this.profile ? this.profile.address : '',
                subDistrict: this.profile ? this.profile.subDistrict : '',
                district: this.profile ? this.profile.district : '',
                province: this.profile ? this.profile.province : '',
                postCode: this.profile ? this.profile.postCode : ''
            };
        },
        initError() {
            return {
                address: false,
                subDistrict: false,
                district: false,
                province: false,
                postCode: false
            };
        },
        async saveAddress() {
            if (this.validate()) {
                let loader = this.$modalLoader.render();
                const result = await UserService.saveAddress(this.form);
                loader.hide();

                if (result.data?.success) {
                    this.hide(true);
				} else {
					this.$swal(Helper.warningAlert('ไม่สามรถบันทึกข้อมูลที่อยู่ได้', result.data?.message));
				}
            }
        },
        validate() {
            this.error = this.initError();
            let isValid = true;
            let isBlank = false;

            // Check Blank
            for (const property in this.error) {
                if (!this.form[property]?.trim()) {
                    this.error[property] = true;
                    isValid = false;
                    isBlank = true;
                }
            }

            // Popup
            if (isBlank) {
                this.$swal(Helper.warningAlert('ไม่สามรถบันทึกข้อมูลที่อยู่ได้', 'โปรดกรอกข้อมูลให้ครบถ้วน'));
            }

            return isValid;
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.modal-form {
    max-width: 600px;
}
</style>